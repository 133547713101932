<template>
    <div class="kteventsection4_container">
        <div class="ktevent_section4_addbenefit">
            <div class="dp_flex flex_justify_center addbenefit_span_wrap">
                <div class="addbenefitspan">어플레이즈 추가 혜택</div>
            </div>
            <p class="addbenefit_desc">
                어플레이즈를 통해 사전 예약 및 개통 시<br />
                추가 혜택을 드립니다!
            </p>
            <ul class="dp_flex addbenefit_list">
                <li class="addbenefit_item left">
                    <h4 class="addbenefit_item_name">어플레이즈 2개월 이용권</h4>
                    <figure class="addbenefit_img_wrap dp_flex flex_justify_center">
                        <img class="addbenefit_img1" src="/media/img/collaboration/ktevent_section4_gift1.png" alt="2개월 쿠폰" />
                    </figure>
                </li>
                <li class="addbenefit_item">
                    <h4 class="addbenefit_item_name">네이버페이 3만원</h4>
                    <figure class="addbenefit_img2_wrap dp_flex flex_justify_center">
                        <img
                            class="addbenefit_img2"
                            src="/media/img/collaboration/ktevent_section4_gift2.png"
                            alt="네이버페이 3만원"
                        />
                    </figure>
                </li>
            </ul>
        </div>
    <!-- KT 이벤트 종료로 인하여 버튼 제거 -->
    <!-- <div class="dp_flex reservation_btn_group flex_column align_center">
      <button class="reservation_btn" @click="onClickBtn1">
        Fold6 사전 예약
        <img
          class="reservation_arrow_img"
          src="/media/img/collaboration/ktevent_section4_arrow.png"
          alt="ktevent_section4_arrow"
        />
      </button>
      <button class="reservation_btn" @click="onClickBtn2">
        Flip6 사전 예약
        <img
          class="reservation_arrow_img"
          src="/media/img/collaboration/ktevent_section4_arrow.png"
          alt="ktevent_section4_arrow"
        />
      </button>
      <button class="reservation_btn" @click="onClickBtn3">
        마루는 강쥐 에디션 사전 예약
        <img
          class="reservation_arrow_img"
          src="/media/img/collaboration/ktevent_section4_arrow.png"
          alt="ktevent_section4_arrow"
        />
      </button>
    </div> -->
    </div>
</template>
<script>
import { isNotPc } from '@/utils/Utils';
export default {
  name: 'KtEventSection4',

  data () {
    return {
      isPc: !isNotPc()
    };
  },

  mounted () {},

  methods: {
    onClickBtn1 () {
      this.isPc = !isNotPc();
      let openUrl = 'https://shop.kt.com/wireless/mobileList.do?&prodNo=WL00071906';
      if (!this.isPc) {
        openUrl = 'https://m.shop.kt.com:444/m/wireless/mobileList.do?&prodNo=WL00071906';
      }

      window.open(openUrl, '_blank');
    },
    onClickBtn2 () {
      this.isPc = !isNotPc();

      let openUrl = 'https://shop.kt.com/wireless/mobileList.do?&prodNo=WL00071905';
      if (!this.isPc) {
        openUrl = 'https://m.shop.kt.com:444/m/wireless/mobileList.do?&prodNo=WL00071905';
      }

      window.open(openUrl, '_blank');
    },
    onClickBtn3 () {
      this.isPc = !isNotPc();

      let openUrl = 'https://shop.kt.com/wireless/mobileList.do?&prodNo=WL00071909';
      if (!this.isPc) {
        openUrl = 'https://m.shop.kt.com:444/m/wireless/mobileList.do?&prodNo=WL00071909';
      }

      window.open(openUrl, '_blank');
    }
  }
};
</script>
<style src="@/assets/css/collaboration/collaborfont.css"></style>
<style scoped src="@/assets/css/collaboration/kteventsection4.css"></style>
